<template>
  <div v-if="canViewReportTeam">
    <b-card
      no-body
      class="sticky-top-65"
    >
      <b-card-header class="justify-content-between gap-2 py-1">
        <div class="d-flex align-items-end">
          <b-badge
            variant="light-primary"
            class="font-small-4 px-1 py-50 text-uppercase"
          >
            BÁO CÁO | {{ currentDepartmentName }}
          </b-badge>
          <span class="ml-1 font-weight-bolder font-medium-1">{{ reportGeneralTitle }}</span>
        </div>
        <div class="d-flex flex-wrap">
          <div class="d-flex mr-1 gap-1 align-items-center">
            <label class="text-nowrap">Tháng</label>
            <v-select
              v-model="monthFilter"
              class="select-size-sm"
              label="name"
              :options="$monthOptions"
              :clearable="true"
            />
          </div>
          <div class="d-flex gap-1 align-items-center">
            <label class="text-nowrap">Năm</label>
            <v-select
              v-model="yearFilter"
              class="select-size-sm"
              label="name"
              :options="$yearOptions"
              :clearable="true"
            />
          </div>
        </div>
      </b-card-header>
    </b-card>

    <plan-list
      v-if="canViewPlanWorkReportTeam"
      :ref-name="'table-max-height-scroll'"
      :show-filter="false"
      :prop-class="'table-max-height-scroll'"
      :is-show-create-new="true"
      :auto-select-department-id="departmentId.toString()"
      :prop-year-filter="(yearFilter || '').toString()"
      :prop-month-filter="(monthFilter || '').toString()"
    />

    <table-h-s
      v-if="sumData.report_department_HS"
      :table-data="tableDataHS"
      :refs="sumData.report_department_HS.report_know_with"
    />

    <table-h-d
      v-if="sumData.report_department_HD"
      :table-data="tableDataHD"
      :refs="sumData.report_department_HD.report_know_with"
      :time-filter="timeFilter"
    />

    <table-doanh-thu
      v-if="sumData.debt_detail"
      :table-data="tableDoanhThu"
      @open-modal-debt-detail="openModalDebtDetail"
    />

    <debt-collection-list
      v-if="canViewDebtPlanInReportTeam"
      :show-filter="false"
      :is-show-create-new="true"
      :auto-select-department-id="departmentId.toString()"
      :prop-year-filter="(yearFilter || '').toString()"
      :prop-month-filter="(monthFilter || '').toString()"
    />

    <TeamNote
      ref="reportsTeam-teamNote"
      :member-data="teamLeader"
      :review-data="teamLeaderReview"
      :team-notes="currentDepartmentNotes"
      :text-total-stars="textTotalStars"
      :monthFilter="monthFilter"
      @create-department-review="data => createDepartmentReview(data)"
      @update-department-review="data => updateDepartmentReview(data)"
      @create-team-note="data => handleCreateTeamNote(data)"
      @handle-delete-note="id => handleDeleteNote(id)"
    />

    <reports-members
      :report-members="membersInTeam"
      :report-plan="departmentReportPlan"
      :team-fund="(sumData.team_fund && sumData.team_fund[0])"
      :team-leader="teamLeader"
      :team-leader-report="teamLeaderReview"
      @go-detail="goReportUser"
      @export-team-report-member="handleExportReportTeamMember"
    />
    <result-vote
      v-if="ability().can('view', 'Vote')"
      :result-votes="allDepartmentVoteWithUserNames"
      @go-detail="goReportUser"
    />

    <!--    fixed btn-->
    <fixed-right-button
      :buttons="actionsButton"
      @click="handleActionsButton"
    />

    <b-modal
      ref="modal-debt-detail"
      ok-title="Ok"
      ok-only
      size="xl"
      centered
      header-text-variant="primary"
      title="Chi tiết thu nợ"
    >
      <b-card-text>
        <b-table
          ref="refUsersListTable"
          class="position-relative"
          responsive
          hover
          small
          bordered
          primary-key="id"
          show-empty
          empty-text="Không có kết quả nào"
          :items="debtCollectionReportList"
          :fields="tableColumnsDebtCollectionReport"
          @row-clicked="gotoContractDetail"
        >
          <template #cell(code)="data">
            <span class="text-primary text-nowrap">
              {{ data.item.contract.contract_code || data.item.contract.document_code }}
            </span>
          </template>
          <template #cell(doi)="data">
            <span class="text-primary text-nowrap">{{ data.item.department && data.item.department.name }}</span>
          </template>
          <template #cell(ngay-thu)="data">
            <span class="text-nowrap">{{ formatDate(data.item.collect_at) }}</span>
          </template>
          <template #cell(thu-duoc)="data">
            <span class="text-primary text-nowrap">{{ formatNumber(data.item.amount) }} đ</span>
          </template>
          <template #cell(loai-no)="data">
            <span class="text-nowrap">{{ data.item.debt_type === 'origin' ? 'Nợ gốc' : 'Nợ lãi' }}</span>
          </template>
          <template #cell(phi-dv)="data">
            <span class="text-nowrap">{{ formatNumber(parseFloat(data.item.fee_to_buy_original || 0)) }} %</span>
          </template>
          <template #cell(tong-pdv)="data">
            <span class="text-primary text-nowrap">{{ formatNumber(parseFloat(data.item.amount_to_buy_original || 0)) }} đ</span>
          </template>
        </b-table>
        <br>
      </b-card-text>
    </b-modal>
    <b-toast
      id="my-toast"
      variant="warning"
      solid
      title="Thông báo"
      :no-auto-hide="true"
    >
      <div class="d-flex flex-column ga-2">
        <span class="sl-16">
          Bạn chưa bình chọn cho tháng {{ beforeMonth.month }}/{{ beforeMonth.year }}. Vui lòng bình chọn nhân viên xuất sắc nhất tháng  {{ beforeMonth.month }}/{{ beforeMonth.year }}.
        </span>
        <b-button
          variant="primary"
          @click="openModalVote"
        >
          Bình chọn
        </b-button>
      </div>
    </b-toast>
    <b-modal
      id="modal-vote-before-month"
      ref="modal-vote-before-month"
      ok-title="Bình chọn"
      cancel-title="Huỷ"
      size="xl"
      centered
      header-text-variant="primary"
      :title="`Bình chọn nhân viên xuất sắc tháng ${beforeMonth.month}/${beforeMonth.year}`"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <b-card-text>
        <validation-observer ref="formVoteRules">
          <b-form
            id="form-vote"
            ref="formVote"
            @submit.stop.prevent="handleVoteUser"
          >
            <b-form-group
              label="Chọn nhân viên xuất sắc"
              label-for="selectedVote"
            >
              <validation-provider
                #default="{ errors }"
                :name="'provider-n-user_id'"
                rules="required"
                :vid="'provider-v-user_id'"
                class="position-relative d-block"
              >
                <v-select
                  v-model="selectVoteUserId"
                  label="user_name"
                  :options="optionsVote"
                  :clearable="false"
                  input-id="user_id"
                  :required="true"
                  :reduce="option => option.user_id"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >Vui lòng chọn nhân viên xuất sắc</small>

              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
  <div
    v-else
    class="text-danger text-center"
  >
    Bạn chưa đủ quyền truy cập trang này, vui lòng liên hệ admin
  </div>
</template>

<script>
import {
  BCard, BCardHeader, VBTooltip, BBadge, BTable, BCardText, BButton, BForm, BFormGroup,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ReportsMembers from '@/views/reports/team/Members.vue'
import ResultVote from '@/views/reports/team/ResultVote.vue'
import reportStoreModule from '@/views/reports/reportStoreModule'
import vSelect from 'vue-select'
import http from '@/global/http'
import FixedRightButton from '@core/components/fixed-button/Fixed-Right-Button.vue'
import ability from '@/libs/acl/ability'
import TableHS from '@/views/reports/team/TableHS.vue'
import TableHD from '@/views/reports/team/TableHD.vue'
import TableDoanhThu from '@/views/reports/team/TableDoanhThu.vue'
import { getUserData } from '@/auth/utils'
import DebtCollectionList from '@/views/reports/debt-collection/list/DebtCollectionList.vue'
import PlanList from '@/views/plans/list/PlanList.vue'
import departmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import TeamNote from '@/views/reports/team/TeamNote.vue'
import { getBeforeMonth } from '@/utils/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import useReportsOverview from '../overview/useReportsOverview'

export default {
  components: {
    BCardText,
    BTable,
    TeamNote,
    PlanList,
    DebtCollectionList,
    ReportsMembers,
    BCard,
    BCardHeader,
    BBadge,
    TableHS,
    TableHD,
    TableDoanhThu,
    vSelect,
    FixedRightButton,
    BButton,
    BForm,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    ResultVote,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      ability,
      beforeMonth: getBeforeMonth(),
    }
  },
  computed: {
    tableColumnsDebtCollectionReport() {
      return [
        {
          label: 'Mã',
          key: 'code',
        },
        {
          label: 'Tên đội',
          key: 'doi',
          tdClass: 'text-nowrap',
        },
        {
          label: 'Ngày thu',
          key: 'ngay-thu',
          stickyColumn: true,
        },
        {
          label: 'Thu được',
          key: 'thu-duoc',
        },
        {
          label: 'Loại',
          key: 'loai-no',
          tdClass: 'text-nowrap',
        },
        {
          label: 'Phí DV',
          key: 'phi-dv',
        },
        {
          label: 'Tổng PDV',
          key: 'tong-pdv',
        },
      ]
    },
    canViewReportTeam() {
      return (this.ability()
        .can('read', 'Reports') && this.ability()
        .can('read', 'DebtCollectionMenu')) || this.ability()
        .can('read', 'ReportsMyTeam')
    },
    canViewPlanWorkReportTeam() {
      return this.ability()
        .can('read', 'Plans') || this.ability()
        .can('read', 'TeamPlan')
    },
    canViewDebtPlanInReportTeam() {
      return this.ability()
        .can('read', 'DebtCollection')
    },
    timeFilter() {
      if (!this.yearFilter) return 'Tất cả'
      if (this.monthFilter) return `T${this.monthFilter}/${this.yearFilter}`
      return `năm ${this.yearFilter}`
    },
    tableDataHS() {
      const tableData = []
      const data = this.sumData.report_department_HS || {}

      tableData[0] = {
        ...data,
        row_name: 'Số lượng',
      }
      tableData[1] = {
        row_name: 'Giá trị nợ',
        contract_count: data.total_sum,
        company: data.sum_company,
        personal: data.sum_personal,
        offer_to_sign: data.total_sum_offer_to_sign,
        verify_deny: data.total_sum_verify_deny,
        verify_new: parseFloat(data.total_sum_verify_new || 0) + parseFloat(data.total_sum_verify_approving || 0),
        total_offer_to_sign: data.total_sum_all_offer_to_sign,
      }
      return tableData
    },
    tableDataHD() {
      const tableData = []
      const data = this.sumData.report_department_HD || {}

      tableData[0] = {
        ...data,
        row_name: 'Số lượng',
      }
      tableData[1] = {
        row_name: 'Giá trị nợ',
        total_all_contract: data.sum_total_all_contract,
        is_hand_over: data.sum_count_is_hand_over,
        company: data.sum_company,
        personal: data.sum_personal,
        offer_to_sign: data.total_sum_offer_to_sign,
        verify_deny: data.total_sum_verify_deny,
        verify_new: data.total_sum_verify_new,
        has_plan: data.sum_has_plan,
        no_plan: data.sum_no_plan,
      }
      return tableData
    },
    tableDoanhThu() {
      const data = this.sumData.debt_detail || {}

      const transactions = data.sum_transactions || []
      const {
        amountTeamLeader,
        amountTeamMember,
      } = this.getSalaryTeam(transactions)
      return [{
        ...data,
        amountTeamLeader,
        amountTeamMember,
      }]
    },
    actionsButton() {
      const actions = [
        {
          label: 'Xuất excel đội',
          icon: 'DownloadIcon',
          value: 'export-excel-report-team',
        },
      ]
      if (ability()
        .can('export_excel_report_team_current_contract')) {
        actions.push(
          {
            label: 'Xuất HĐ đang thực hiện',
            icon: 'DownloadIcon',
            value: 'export-excel-current-contract',
          },
        )
      }
      return actions
    },
    isTeamLeader() {
      const userData = getUserData()
      return userData.job_title_code.toLowerCase() === 'tdpc'
    },
    optionsVote() {
      const data = this.sumData.sum_transactions || []
      const userData = getUserData()
      if (this.isTeamLeader) {
        return data.filter(d => d.user_id !== userData.id && !this.TDPCIds.includes(d.user_id))
      }
      return data.filter(d => !this.TDPCIds.includes(d.user_id))
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.reportMode = this.$route.name
        const q = this.$route.query || {}
        const userData = getUserData() || {}
        const myTeam = userData.department || {}
        this.currentDepartmentName = q.name || myTeam.name || 'Đội'
        this.reportTeamType = q.type
        this.departmentId = (this.$route.params.id || '').toString() || (myTeam.id || '').toString()
        if (q.monthFilter !== undefined && q.yearFilter !== undefined) {
          this.monthFilter = q.monthFilter || ''
          this.yearFilter = q.yearFilter || ''
        }
        if (q.ref) {
          setTimeout(() => {
            this.scrollToRef(q.ref)
          }, 2000)
        }
      },
    },
    reportGeneralTitle() {
      const q = this.$route.query
      if ((this.monthFilter !== q.monthFilter || this.yearFilter !== q.yearFilter) && this.reportMode !== 'reports-my-team') {
        this.$router.push({
          name: 'reports-team',
          query: {
            name: this.currentDepartmentName,
            monthFilter: this.monthFilter,
            yearFilter: this.yearFilter,
            t: new Date().getTime(),
          },
        })
      }
    },
  },
  mounted() {
    window.__reportTeam = this
    if (ability().can('read', 'ReportsMyTeam')) {
      this.fetchMyVote().then(() => {
        const findIndex = this.myVoteLists.findIndex(d => d.month === this.beforeMonth.month && d.year === this.beforeMonth.year)
        if (findIndex === -1) {
          this.showToastVoteBeforeMonth()
        }
      })
    }

    if (ability().can('view', 'Vote')) {
      this.fetchDepartmentVote()
      this.fetchAllDepartmentVote()
    }
  },
  methods: {
    scrollToRef(refName) {
      this.$nextTick(() => {
        const component = this.$refs[refName]
        const element = component.$el

        if (element && typeof element.scrollIntoView === 'function') {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      })
    },
    gotoContractDetail(contract) {
      const id = contract.contract_id || contract.contract.id
      this.$router.push({
        name: 'contracts-detail',
        params: { id },
        query: { t: Date.now() },
      })
    },
    openModalDebtDetail(data) {
      this.$refs['modal-debt-detail'].show()
      this.fetchDebtCollectionDetailReport(data.department_id)
    },
    handleExportReportTeamMember() {
      this.exportTeamReportMember().then(res => {
        const link = res.data || ''
        this.openLinkInNewTab(link)
      })
    },
    handleActionsButton(btn) {
      if (btn.value === 'export-excel-report-team') {
        const {
          from,
          to,
        } = this.getDateRange()
        store.dispatch('app/exportExcelReportTeam', {
          department_id: this.departmentId,
          q: http.buildQuery({
            from: http.getISODate(from),
            to: http.getISODate(to),
          }),
        })
          .then(res => {
            this.openLinkInNewTab(res.data.data)
          })
      }
      if (btn.value === 'export-excel-current-contract') {
        const {
          from,
          to,
        } = this.getDateRange()
        store.dispatch('app/exportReportTeamCurrentContract', {
          department_id: this.departmentId,
          q: http.buildQuery({
            from: http.getISODate(from),
            to: http.getISODate(to),
          }),
        })
          .then(res => {
            this.openLinkInNewTab(res.data.data)
          })
      }
    },
    goReportUser(d) {
      const userData = getUserData()
      if (d.user_id === userData.id) {
        this.$router.push({
          name: 'reports-me',
        })
      } else {
        this.$router.push({
          name: 'reports-user',
          params: { id: d.user_id },
          query: {
            monthFilter: this.monthFilter,
            yearFilter: this.yearFilter,
            t: new Date().getTime(),
          },
        })
      }
    },
    showToastVoteBeforeMonth() {
      this.$bvToast.show('my-toast')
    },
    openModalVote() {
      this.$bvToast.hide('my-toast')
      this.$refs['modal-vote-before-month'].show()
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleVoteUser()
    },
    handleVoteUser() {
      // Exit when the form isn't valid
      this.$refs.formVoteRules.validate().then(success => {
        if (success) {
        // eslint-disable-next-line
          this.voteBestUserMonth({
            voted_user_id: this.selectVoteUserId,
            month: this.beforeMonth.month,
            year: this.beforeMonth.year,
            department_id: Number(this.departmentId),
          }, () => this.$bvModal.hide('modal-vote-before-month'))
        }
      })
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, departmentStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
    })

    return {
      ...useReportsOverview(),
    }
  },
}
</script>

<style lang="scss">
</style>
