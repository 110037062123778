<template>
  <b-card
    class="custom-border-top-1px"
  >
    <b-card-header class="header-team-note pl-0 pt-0">
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        GHI CHÚ & ĐÁNH GIÁ
      </b-badge>
      <b class="sl-18 position-center text-primary" v-if="textTotalStars">Kết quả đánh giá TDPC tháng {{ monthFilter }}: {{ textTotalStars }}</b>
    </b-card-header>

    <b-row
      v-if="memberData"
    >
      <b-col
        cols="12"
        sm="5"
        class="position-relative"
      >
        <b-button
          v-if="$ability().can('create', 'TeamLeaderRating')"
          variant="primary"
          size="sm"
          class="cursor-pointer text-primary absolute-top-right text-nowrap"
          @click="openModalCreateDepartmentReview"
        >
          <feather-icon
            class="text-white"
            icon="PlusIcon"
          />
          Đánh giá
        </b-button>
        <div class="info-wrapper">
          <span class="info-label text-primary">
            <b-badge
              variant="light-primary"
              class="mr-1"
            >
              <span>{{ memberData.job_title_code }}</span>
            </b-badge>
            <span>{{ memberData.fullname }}</span>
          </span>
          <span class="info-value text-primary" />
        </div>

        <div class="review-list-wrapper">
          <div
            v-for="(review, reviewIdx) in reviewData"
            :key="reviewIdx"
            class="review-item"
          >
            <div class="review-item-top">
              <div class="d-flex justify-content-between">
                <div
                  class="text-primary cursor-pointer"
                  @click="openModalDepartmentReview(review)"
                >
                  <span>Đánh giá tháng </span><span>{{ getMonthByDate(review.rated_at) }}</span>/<span>{{ getYearByDate(review.rated_at) }}</span>
                </div>
                <div class="review-item-action">

                  <feather-icon
                    v-if="$ability().can('create', 'TeamLeaderRating')"
                    class="ml-1 cursor-pointer text-primary"
                    icon="EditIcon"
                    size="16"
                    @click="openModalDepartmentReview(review)"
                  />

                  <feather-icon
                    v-if="$ability().can('create', 'TeamLeaderRating')"
                    class="ml-1 cursor-pointer text-danger"
                    icon="XIcon"
                    size="16"
                    @click="openModalConfirmDeleteDepartmentReview(review)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="7"
      >

        <div class="d-flex justify-content-end mb-1">
          <b-button
            v-if="$ability().can('create', 'TeamLeaderRating')"
            v-b-modal="'modal-comment'"
            variant="primary"
            size="sm"
            @click="openModalAddTeamNote"
          >
            <feather-icon
              icon="PlusIcon"
            />
            Ghi chú đội
          </b-button>
        </div>

        <div class="department-notes-wrapper">

          <app-timeline
            class="d-flex flex-column align-items-end"
          >
            <app-timeline-item-custom
              v-for="(note, noteIdx) in teamNotes"
              :id="note.id.toString()"
              :key="noteIdx"
              :data="note"
              :title="note.user && note.user.fullname"
              :full-width="true"
              :show-btn-edit="ability().can('edit', 'NotePlan') && false"
              :show-btn-delete="ability().can('delete', 'NotePlan') && true"
              :subtitle="note.content"
              icon="UserIcon"
              :time="formatDate(note.created_at, true)"
              variant="primary"
              @edit="val => $emit('handle-edit-note', val)"
              @delete="val => $emit('handle-delete-note', val)"
            />
          </app-timeline>

        </div>
      </b-col>
    </b-row>

    <b-modal
      ref="modal-add-team-note"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="primary"
      ok-variant="primary"
      :title="'Thêm ghi chú cho ' + currentDepartmentName"
      @ok="handleAddTeamNote"
    >
      <b-card-text>

        <b-form-group>
          <div>
            Thời gian: <span>tháng </span><span>{{ monthFilter }}</span>/<span>{{ yearFilter }}</span>
          </div>
        </b-form-group>

        <b-form-group label-for="plan-review">
          <b-form-textarea
            v-model="popupTeamNoteData"
            rows="3"
            :placeholder="'Ghi chú mới cho ' + currentDepartmentName"
          />
        </b-form-group>

        <label class=""><small>(*) Ghi chú sẽ được tạo ở <span class="font-weight-bolder text-primary">thời gian thực</span>, không bị ảnh hưởng bởi <span class="font-weight-bolder text-primary">bộ lọc</span> thời gian</small></label>
      </b-card-text>
    </b-modal>

    <b-modal
      ref="modal-confirm-delete-team-review"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xóa đánh giá!"
      @ok="handleDeleteDepartmentReview"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> đánh giá ngày
        <span
          v-if="currentDetailDepartmentReview"
          class="text-primary font-weight-bolder"
        >{{ formatDate(currentDetailDepartmentReview.rated_at) }}</span>
      </b-card-text>
    </b-modal>

    <b-modal
      ref="modal-create-department-review"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="primary"
      ok-variant="primary"
      title="Đánh giá đội trưởng"
      @ok="handleCreateDepartmentReview"
    >
      <b-card-text v-if="currentDetailDepartmentReview">
        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">{{ memberData.fullname }} <small>({{
              memberData.job_title_code
            }})</small></label>
            <div>
              <span>tháng </span><span>{{ monthFilter }}</span>/<span>{{ yearFilter }}</span>
            </div>
          </div>
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Chấp hành quy chế công ty</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_quy_che_cong_ty"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_quy_che_cong_ty"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Kế hoạch làm việc</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_ke_hoach_lam_viec"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_ke_hoach_lam_viec"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Mức độ tham gia công việc</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_tham_gia_cong_viec"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_tham_gia_cong_viec"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Thái độ ứng xử với nhân viên</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_thai_do_nhan_vien"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_thai_do_nhan_vien"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Thái độ ứng xử với cấp trên</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_thai_do_cap_tren"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_thai_do_cap_tren"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Thái độ ứng xử với đồng nghiệp</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_thai_do_dong_nghiep"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_thai_do_dong_nghiep"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Làm việc theo quy trình</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_lam_viec_theo_quy_trinh"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_lam_viec_theo_quy_trinh"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Tham gia phong trào đoàn thể</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_tham_gia_phong_trao"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_tham_gia_phong_trao"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Bảo quản trang thiết bị</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_bao_quan_trang_thiet_bi"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_bao_quan_trang_thiet_bi"
            trim
          />
        </b-form-group>

        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">Giữ gìn vệ sinh nơi làm việc</label>

            <b-form-rating
              id="rating-10"
              v-model="currentDetailDepartmentReview.rating_ve_sinh_noi_lam_viec"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="currentDetailDepartmentReview.reason_ve_sinh_noi_lam_viec"
            trim
          />
        </b-form-group>

      </b-card-text>
      <div v-else>
        Không tìm thấy thông tin
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  VBTooltip,
  BBadge,
  BRow,
  BCardBody,
  BCol,
  BButton,
  BCardText,
  BFormGroup,
  BFormRating,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import reportStoreModule from '@/views/reports/reportStoreModule'
import ability from '@/libs/acl/ability'
import { getUserData } from '@/auth/utils'
import AppTimelineItemCustom from '@core/components/app-timeline/AppTimelineItemCustom.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import useReportsOverview from '../overview/useReportsOverview'

export default {
  components: {
    AppTimeline,
    AppTimelineItemCustom,
    BFormInput,
    BFormRating,
    BFormGroup,
    BCardText,
    BButton,
    BBadge,
    BCol,
    BCardBody,
    BRow,
    BCard,
    BCardHeader,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    teamNotes: {
      type: Array,
      default: () => [],
    },
    memberData: {
      type: Object,
      default: () => {
      },
    },
    reviewData: {
      type: Array,
      default: () => [],
    },
    textTotalStars: {
      type: String,
      default: '',
    },
    monthFilter: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      ability,
    }
  },
  computed: {
    canViewReportTeam() {
      return (this.ability()
        .can('read', 'Reports') && this.ability()
        .can('read', 'DebtCollectionMenu')) || this.ability()
        .can('read', 'ReportsMyTeam')
    },
    canViewPlanWorkReportTeam() {
      return this.ability()
        .can('read', 'Plan')
    },
    canViewDebtPlanInReportTeam() {
      return this.ability()
        .can('read', 'DebtCollection')
    },
    timeFilter() {
      if (!this.yearFilter) return 'Tất cả'
      if (this.monthFilter) return `T${this.monthFilter}/${this.yearFilter}`
      return `năm ${this.yearFilter}`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.reportMode = this.$route.name
        const q = this.$route.query || {}
        const userData = getUserData() || {}
        const myTeam = userData.department || {}
        this.currentDepartmentName = q.name || myTeam.name || 'Đội'
        this.reportTeamType = q.type
        this.departmentId = (this.$route.params.id || '').toString() || (myTeam.id || '').toString()
        if (q.monthFilter !== undefined && q.yearFilter !== undefined) {
          this.monthFilter = q.monthFilter || ''
          this.yearFilter = q.yearFilter || ''
        }
      },
    },
    reportGeneralTitle() {
      const q = this.$route.query
      if ((this.monthFilter !== q.monthFilter || this.yearFilter !== q.yearFilter) && this.reportMode !== 'reports-my-team') {
        this.$router.push({
          name: 'reports-team',
          query: {
            name: this.currentDepartmentName,
            monthFilter: this.monthFilter,
            yearFilter: this.yearFilter,
            t: new Date().getTime(),
          },
        })
      }
    },
  },
  methods: {
    handleDeleteDepartmentReview(review) {
      this.removeDepartmentReview(review)
    },
    openModalDepartmentReview(review) {
      this.currentDetailDepartmentReview = review
      this.currentDetailDepartmentReviewMode = 'edit'
      this.$refs['modal-create-department-review'].show()
    },
    openModalConfirmDeleteDepartmentReview() {
      this.popupTeamReview = ''
      this.currentDetailDepartmentReviewMode = 'create'
      this.$refs['modal-confirm-delete-team-review'].show()
    },
    openModalAddTeamNote() {
      this.popupTeamNoteData = ''
      this.$refs['modal-add-team-note'].show()
    },
    handleAddTeamNote() {
      this.$emit('create-team-note', this.popupTeamNoteData)
    },
    openModalCreateDepartmentReview() {
      this.currentDetailDepartmentReviewMode = 'create'
      this.$refs['modal-create-department-review'].show()
    },
    handleCreateDepartmentReview() {
      if (this.currentDetailDepartmentReviewMode === 'edit') {
        this.$emit('update-department-review', this.currentDetailDepartmentReview)
      } else this.$emit('create-department-review', this.currentDetailDepartmentReview)
    },
    goReportUser(d) {
      const userData = getUserData()
      if (d.user_id === userData.id) {
        this.$router.push({
          name: 'reports-me',
        })
      } else {
        this.$router.push({
          name: 'reports-user',
          params: { id: d.user_id },
          query: {
            monthFilter: this.monthFilter,
            yearFilter: this.yearFilter,
            t: new Date().getTime(),
          },
        })
      }
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    return {
      ...useReportsOverview(),
    }
  },
}
</script>

<style lang="scss">
.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.department-notes-wrapper {
  margin-bottom: 1rem;
  max-height: 200px;
  overflow-y: scroll;
}

.review-list-wrapper {
  margin-top: 2rem;

  .review-item {
    margin-bottom: 1rem;
  }
}
.position-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .position-center {
    right: 0;
    left: unset;
    transform: unset
  }
  .sl-18 {
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
  .card .card-header.header-team-note {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .position-center {
    position: relative;
  }
}
</style>
