<template>
  <b-card class="custom-border-top-1px">
    <b-card-header class="pl-0 pt-0">
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        KẾT QUẢ BÌNH CHỌN
      </b-badge>
    </b-card-header>

    <b-table
      class="position-relative"
      responsive
      bordered
      striped
      primary-key="id"
      show-empty
      empty-text="Không có kết quả nào"
      :items="resultVotes"
      :fields="tableColumnsResultVoted"
    >
      <!-- Column: stt -->
      <template #cell(stt)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>

      <!-- Column: tên đội -->
      <template #cell(user_name)="data">
        <div
          class="text-primary cursor-pointer"
          @click="$emit('go-detail', {user_id: data.item.voter_id})"
        >
          {{ data.item["voter_name"] }}
        </div>
      </template>

      <template #cell(user_name_vote)="data">
        <div
          class="text-primary cursor-pointer"
          @click="$emit('go-detail', {user_id: data.item.voted_user_id})"
        >
          {{ data.item["voted_user_name"] }}
        </div>
      </template>

      <!-- Column: lương thu -->
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BTable, VBTooltip, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCardHeader,
    BCard,
    BTable,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    resultVotes: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const tableColumnsResultVoted = [
      {
        label: 'STT',
        key: 'stt',
      },
      {
        label: 'Tên',
        key: 'user_name',
        tdClass: 'minw-150',
      },
      {
        label: 'Bình chọn cho',
        key: 'user_name_vote',
      },
    ]
    return {
      tableColumnsResultVoted,
    }
  },
}
</script>
