<template>
  <div v-if="$ability().can('read', 'DebtCollectionMenu') || $ability().can('read', 'DebtCollection')">
    <!-- Filters -->
    <b-card
      v-if="showFilter"
      no-body
    >
      <b-card-body class="pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            lg="3"
            class="mb-md-0 mb-50"
          >
            <label>Phòng ban</label>
            <v-select
              v-model="departmentFilter"
              :options="departmentOptions"
              class="w-100 select-size-sm"
              label="name"
              :reduce="val => val.id"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            lg="1"
            class="mb-md-0 mb-50 pl-md-0"
          >
            <label>Tháng</label>
            <v-select
              v-model="monthFilter"
              class="w-100 select-size-sm v-select-hiden-autocomplete"
              label="name"
              :options="$monthOptions"
              :clearable="true"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            lg="1"
            class="mb-md-0 mb-50 pl-md-0"
          >
            <label>Năm</label>
            <v-select
              v-model="yearFilter"
              class="w-100 select-size-sm v-select-hiden-autocomplete"
              label="name"
              :options="$yearOptions"
              :clearable="true"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="custom-border-top-1px"
    >
      <b-card-header class="pl-0 pt-0">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO THU NỢ
        </b-badge>
        <b-button
          v-if="$ability().can('create', 'DebtCollection') && isShowCreateNew"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="text-nowrap"
          @click="popupAddDebtCollection"
        >
          <feather-icon
            class="text-white"
            icon="PlusIcon"
          />
          Thêm mới
        </b-button>
      </b-card-header>

      <b-table
        ref="refUsersListTable"
        class="position-relative text-center table-col-min-width-100 table-td-text-nowrap"
        responsive
        primary-key="id"
        show-empty
        bordered
        small
        empty-text="Không có kết quả nào"
        :items="debtCollections"
        :fields="tableColumns"
      >
        <template #thead-top>
          <b-tr>
            <b-th
              colspan="5"
              class="text-primary border-right-gray"
            >
              DỰ KIẾN KẾ HOẠCH THU NỢ {{ timeFilter }}
            </b-th>
            <b-th
              colspan="4"
              class="text-primary"
            >
              KẾT QUẢ THỰC HIỆN
            </b-th>
          </b-tr>
        </template>

        <!--stt-->
        <template #cell(stt)="data">
          <div>
            {{ data.index + 1 }}
          </div>
        </template>

        <!--date-->
        <template #cell(date)="data">
          <div>
            {{ formatDate(data.item.schedule_at) }}
          </div>
        </template>

        <!--team-->
        <template #cell(team)="data">
          <div
            class="text-primary cursor-pointer"
            @click="goReportTeam(data.item)"
          >
            {{ data.item.department_name }}
          </div>
        </template>

        <!--so-hd-->
        <template #cell(so-hd)="data">
          <div
            class="text-primary cursor-pointer"
            @click="gotoContractDetail(data.item)"
          >
            {{ data.item.contract && data.item.contract.contract_code || ('#' + data.item.contract_id) }}
          </div>
        </template>

        <!--so-tien-thu-->
        <template #cell(so-tien-thu)="data">
          <div class="text-right">
            {{ formatNumber(data.item.expected_amount) }} đ
          </div>
        </template>

        <!--so-tien-thuc-thu-->
        <template #cell(so-tien-thuc-thu)="data">
          <div class="text-right">
            {{ formatNumber(data.item.actual_amount) }} đ
          </div>
        </template>

        <!--dat-duoc-->
        <template #cell(dat-duoc)="data">
          <div
            v-if="data.item.expected_amount > 0"
            class="d-flex align-items-center"
          >
            <b-progress
              class="flex-grow-1"
              :height="'8px'"
              :variant="getVariantByPercent(Math.floor(100 * data.item.actual_amount / data.item.expected_amount))"
              :value="Math.floor(100 * data.item.actual_amount / data.item.expected_amount)"
            />
            {{ Math.floor(100 * data.item.actual_amount / data.item.expected_amount) }}%
          </div>
        </template>

        <!--ket-qua-->
        <template #cell(ket-qua)="data">
          <div class="font-small-2">
            {{ data.item.result }}
          </div>
        </template>

        <!-- Column: Người dùng -->
        <template #cell(nguoi-dung)="data">
          <b-media
            vertical-align="center"
            class="align-items-stretch"
          >
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullname)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                class="cursor-pointer"
                @click="popupEdit(data.item)"
              />
            </template>
            <div class="font-weight-bold text-primary d-flex flex-column justify-content-between h-100">
              <span
                class="cursor-pointer"
                @click="popupEdit(data.item)"
              >
                {{ data.item.fullname }}
              </span>
              <small class="text-muted">{{ data.item.phone }}</small>
            </div>
          </b-media>
        </template>

        <!-- Column: Nhóm người dùng -->
        <template #cell(nhom-nguoi-dung)="data">
          <div class="text-xl-nowrap text-primary font-small-4 mb-50">
            {{ data.item.user_role && data.item.user_role.name }}
          </div>
        </template>

        <!-- Column: Phòng ban -->
        <template #cell(phong-ban)="data">
          <div class="text-primary d-flex flex-column justify-content-between h-100">
            <span>
              {{ data.item.department && data.item.department.name }}
            </span>
            <small
              v-if="data.item.job_title_code"
              class="text-muted"
            >
              ({{ $userJobTitleOptions.find(u => u.value === data.item.job_title_code).label }})</small>
          </div>
        </template>

        <!-- Column: Hành động -->
        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="primary"
            @click="popupEditDebtCollection(data.item)"
          >Chi tiết
          </b-button>
        </template>

        <!--        Column: Tổng-->
        <template #bottom-row>
          <td class="font-weight-bold text-primary">
            Tổng
          </td>
          <td />
          <td />
          <td />
          <td class="text-right font-weight-bold text-primary">
            {{ formatNumber(totalExpectedAmount) }} đ
          </td>
          <td class="text-right font-weight-bold text-primary">
            {{ formatNumber(totalActualAmount) }} đ
          </td>
          <td class="text-right font-weight-bold text-primary">
            {{ totalPercentAmount }}
          </td>
          <td />
        </template>
      </b-table>

      <div class="mb-3">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mỗi trang</label>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContracts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <sidebar-debt-collection
      :is-show.sync="isPopupDebtModal"
      :department-options="departmentOptions"
      :contract-options="contractList"
      :auto-select-department-id="autoSelectDepartmentId"
      :modal-mode="debtCollectionModalMode"
      :modal-data="debtCollectionModalData"
      :show-btn-input-debt-result="showBtnInputDebtResult"
      @submit="submitForm"
      @delete="openModalConfirmDeleteDebtCollection"
      @input-debt-result="inputDebtResult"
    />

    <b-modal
      ref="modal-confirm-delete-debt-collection"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa thu nợ!"
      @ok="deleteDebtCollection(currentDebt)"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> thu nợ
        <span
          class="text-primary font-weight-bolder"
        >{{ currentDebt.deparment_name }}</span> ngày
        <span
          class="text-primary font-weight-bolder"
        >{{ formatDate(currentDebt.schedule_at) }}</span>?
      </b-card-text>
    </b-modal>

    <b-modal
      ref="modal-input-debt-result"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="success"
      title="Cập nhật thu nợ"
      @ok="editDebtCollectionByAccountant(debtCollectionModalData)"
    >
      <b-card-text>
        Thu nợ ngày <strong>{{ formatDate(debtCollectionModalData.schedule_at) }}</strong> của <strong class="">{{ debtCollectionModalData.department_name }}</strong>
        <br>
        <br>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group>
              <b-input-group>
                <cleave
                  id="amount_to_buy_original"
                  v-model="debtCollectionModalData.actual_amount"
                  class="form-control text-primary"
                  :raw="true"
                  :options="formatOptions.number"
                  placeholder="Tiền thu nợ"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group>
              <label
                class="mb-0"
                for="ket-qua-thu-no"
              >Kết quả thu</label>
              <b-input-group>
                <b-form-input
                  id="ket-qua-thu-no"
                  v-model="debtCollectionModalData.result"
                  type="text"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BCardText,
  BCol,
  BMedia,
  BInputGroup,
  BRow,
  BTr, BTh,
  BPagination,
  BTable,
  BProgress,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'
import { onUnmounted } from '@vue/composition-api'
import departmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import Cleave from 'vue-cleave-component'
import contractStoreModule from '@/views/contracts/contractStoreModule'
import debtCollectionStoreModule from '../debtCollectionStoreModule'
import useDebtCollectionList from './useDebtCollectionList'
import SidebarDebtCollection from './SidebarDebtCollectionDetail.vue'

export default {
  components: {
    SidebarDebtCollection,
    BCardHeader,
    BCardBody,
    BPagination,
    BCardText,
    BInputGroup,
    BCard,
    BTable,
    BProgress,
    BTr,
    BTh,
    BFormGroup,
    BFormInput,
    BBadge,
    BAvatar,
    Cleave,
    BMedia,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    isShowCreateNew: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    showBtnInputDebtResult: {
      type: Boolean,
      default: false,
    },
    autoSelectDepartmentId: {
      type: String,
      default: '',
    },
    propYearFilter: {
      type: String,
      default: '',
    },
    propMonthFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      job_title_code: '',
    }
  },
  computed: {
    timeFilter() {
      if (!this.yearFilter) return 'TẤT CẢ'
      if (this.monthFilter) return `T${this.monthFilter}/${this.yearFilter}`
      return `NĂM ${this.yearFilter}`
    },
    canCreateDebtCollection() {
      return this.job_title_code.toLowerCase() === 'tdpc'
    },
    canUpdateActualDebtCollection() {
      return this.job_title_code.toLowerCase() === 'tdpc'
    },
  },
  watch: {
    propMonthFilter: {
      immediate: true,
      handler(n) {
        // nếu đang ở menu kế hoạch thu nợ, thì set filter tháng, năm, đội mặc định (đội 2)
        if (this.$route.name === 'debt-collection') {
          const m = new Date().getMonth() + 1
          this.monthFilter = m
        } else {
          this.monthFilter = n
        }
      },
    },
    propYearFilter: {
      immediate: true,
      handler(n) {
        // nếu đang ở menu kế hoạch thu nợ, thì set filter tháng, năm, đội mặc định (đội 2)
        if (this.$route.name === 'debt-collection') {
          const y = new Date().getFullYear()
          this.yearFilter = y
        } else {
          this.yearFilter = n
        }
      },
    },
    autoSelectDepartmentId: {
      immediate: true,
      handler(n) {
        this.departmentFilter = n
      },
    },
    yearFilter() {
      this.updateRangeFilter()
    },
    monthFilter() {
      if (!this.yearFilter) return
      this.updateRangeFilter()
    },
  },
  created() {
    this.$action(this.fetchDebtCollections())
    this.$action(this.fetchDepartments())
    // nếu đang ở menu kế hoạch thu nợ, thì set filter tháng, năm, đội mặc định (đội 2)
    // if (this.$route.name === 'debt-collection') {
    //   this.departmentFilter = 5
    // }
  },
  methods: {
    getVariantByPercent(val) {
      if (val >= 100) return 'primary'
      if (val >= 50) return 'warning'
      return 'danger'
    },
    gotoContractDetail(c) {
      const id = c.contract_id
      this.$router.push({
        name: 'contracts-detail',
        params: { id },
      })
    },
    goReportTeam(d) {
      this.$router.push({
        name: 'reports-team',
        params: { id: d.department_id },
        query: {
          name: d.department_name, monthFilter: this.monthFilter, yearFilter: this.yearFilter, t: new Date().getTime(),
        },
      })
    },
    updateRangeFilter() {
      const m = this.monthFilter
      const y = this.yearFilter
      if (!y) {
        this.$emit('update:rangeDateFilter', ' to ')
      } else if (!m) {
        this.$emit('update:rangeDateFilter', `${y}-01-01 to ${y}-12-31`)
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.$emit('update:rangeDateFilter', `${y}-${m}-01 to ${y}-${m}-${endDate}`)
      }
    },
    openModalConfirmDeleteDebtCollection() {
      this.currentDebt = this.debtCollectionModalData || {}
      this.$refs['modal-confirm-delete-debt-collection'].show()
    },
    inputDebtResult() {
      this.currentDebt = this.debtCollectionModalData || {}
      this.$refs['modal-input-debt-result'].show()
    },
    editDebtCollectionByAccountant() {
      const data = {
        ...this.debtCollectionModalData,
        contract_id: parseFloat(this.debtCollectionModalData.contract_id),
        department_id: parseFloat(this.debtCollectionModalData.department_id),
        expected_amount: parseFloat(this.debtCollectionModalData.expected_amount),
        actual_amount: parseFloat(this.debtCollectionModalData.actual_amount),
      }
      this.$action(this.updateDebtCollectionByAccountant(data), true)
    },
    submitForm(modalMode, modalData) {
      const data = {
        ...modalData,
        contract_id: parseFloat(modalData.contract_id),
        department_id: parseFloat(this.autoSelectDepartmentId || modalData.department_id),
        expected_amount: parseFloat(modalData.expected_amount),
        actual_amount: parseFloat(modalData.actual_amount),
      }
      if (modalMode === 'create') {
        this.$action(this.createDebtCollection(data), true)
      }
      if (modalMode === 'edit') {
        this.$action(this.updateDebtCollection(data), true)
      }
    },
    popupAddDebtCollection() {
      this.debtCollectionModalMode = 'create'
      this.isPopupDebtModal = true
      this.debtCollectionModalData = {}
      this.fetchContractList()
    },
    popupEditDebtCollection(row) {
      this.debtCollectionModalMode = 'edit'
      // this.$action(this.fetchDebtCollection(row.id))
      this.debtCollectionModalData = {
        ...row,
        contract_id: row.contract_id.toString(),
      }
      this.isPopupDebtModal = true
      this.fetchContractList()
    },
  },
  setup() {
    const DEBT_COLLECTION_STORE_MODULE_NAME = 'debtCollection'
    const USER_STORE_MODULE_NAME = 'user'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    const CONTRACT_STORE_MODULE_NAME = 'contract'

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule)
    if (!store.hasModule(DEBT_COLLECTION_STORE_MODULE_NAME)) store.registerModule(DEBT_COLLECTION_STORE_MODULE_NAME, debtCollectionStoreModule)
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, departmentStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME)
      if (store.hasModule(DEBT_COLLECTION_STORE_MODULE_NAME)) store.unregisterModule(DEBT_COLLECTION_STORE_MODULE_NAME)
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
    })

    return {
      ...useDebtCollectionList(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card-body .card-body {
  padding: .5rem;
}
</style>
