import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDebtCollections: (ctx, q) => axiosIns.get(`/api.plan${q || ''}`),
    fetchDebtCollection: (ctx, { id }) => axiosIns.get(`/api.plan/${id}`),
    createDebtCollection: (ctx, data) => axiosIns.post('/api.plan', data),
    updateDebtCollection: (ctx, data) => axiosIns.put(`/api.plan/${data.id}`, data),
    updateDebtCollectionByAccountant: (ctx, data) => axiosIns.put(`/api.plan/${data.id}/result`, data),
    deleteDebtCollection: (ctx, { id }) => axiosIns.delete(`/api.plan/${id}`),
  },
}
