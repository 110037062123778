<template>
  <div>
    <b-card class="custom-border-top-1px">
      <b-card-header class="pl-0 pt-0">
        <b-badge
          variant="light-danger"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO HỢP ĐỒNG
        </b-badge>
      </b-card-header>
      <b-table
        class="position-relative text-center table-col-max-width-100 table-col-min-width-100"
        responsive
        bordered
        small
        striped
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="tableData"
        :fields="tableColumns"
      >
        <template #thead-top>
          <b-tr>
            <b-th
              colspan="2"
            />
            <b-th
              colspan="6"
              class="text-primary"
            >
              HỢP ĐỒNG ĐỘI NHẬN {{ timeFilter }}
            </b-th>
          </b-tr>
        </template>

        <template
          #head(department_name)
          rowspan="2"
        />

        <template #cell(total_all_contract)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'contract_signed'}, false)"
          >{{ formatNumber(data.item.total_all_contract || 0) }}</span>
        </template>

        <template #cell(is_hand_over)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_hand_over_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: $status.list.filter(item => item.type === 'hd').map(item => item.value)})"
          >{{ formatNumber(data.item.is_hand_over || 0) }}</span>
        </template>

        <template #cell(company)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_hand_over_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', categoryFilter: 'company'})"
          >{{ formatNumber(data.item.company || 0) }}</span>
        </template>

        <template #cell(personal)="data">
          <span
            class="filterable"
            @click="goContractList({ typeDateFilter: 'from_hand_over_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', categoryFilter: 'personal'})"
          >{{ formatNumber(data.item.personal || 0) }}</span>
        </template>

        <template #cell(has_plan)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_hand_over_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasPlanFilter: 'true'})"
          >{{ formatNumber(data.item.has_plan || 0) }}</span>
        </template>

        <template #cell(no_plan)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_hand_over_at',departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasPlanFilter: 'false'})"
          >{{ formatNumber(data.item.no_plan || 0) }}</span>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, BTable, BTh, BTr, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardHeader,
    BCard,
    BTable,
    BBadge,
    BTr,
    BTh,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    refs: {
      type: Array,
      default: () => [],
    },
    timeFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rangeDateFilter: '',
      tableColumns: [
        {
          label: '',
          key: 'row_name',
          tdClass: 'font-weight-bolder',
        },
        {
          label: 'TỔNG HỢP ĐỒNG ĐANG THỰC HIỆN',
          key: 'total_all_contract',
          formatter: (val, key, item) => this.formatNumber(item.total_all_contract || 0),
        },
        {
          label: 'TỔNG HĐ ĐÃ BÀN GIAO',
          key: 'is_hand_over',
          formatter: (val, key, item) => this.formatNumber(item.is_hand_over || 0),
        },
        {
          label: 'HĐ CÔNG TY',
          key: 'company',
          formatter: (val, key, item) => this.formatNumber(item.company || 0),
        },
        {
          label: 'HĐ CÁ NHÂN',
          key: 'personal',
          formatter: (val, key, item) => this.formatNumber(item.personal || 0),
        },
        {
          label: 'CÓ KẾ HOẠCH TRẢ TIỀN',
          key: 'has_plan',
          formatter: (val, key, item) => this.formatNumber(item.has_plan || 0),
        },
        {
          label: 'CHƯA CÓ KẾ HOẠCH TRẢ TIỀN',
          key: 'no_plan',
          formatter: (val, key, item) => this.formatNumber(item.no_plan || 0),
        },
      ],
    }
  },
  methods: {
    goContractList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    updateRangeFilter() {
      const q = this.$route.query
      const m = q.monthFilter || ''
      const y = q.yearFilter || ''
      if (!y) {
        this.rangeDateFilter = ' to '
      } else if (!m) {
        this.rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return this.rangeDateFilter
    },
  },
}
</script>
