<template>
  <div>
    <b-card class="custom-border-top-1px">
      <b-card-header class="pl-0 pt-0">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO DOANH THU
        </b-badge>
      </b-card-header>
      <b-table
        class="position-relative text-center table-col-max-width-100 table-col-min-width-100"
        responsive
        bordered
        small
        striped
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="tableData"
        :fields="tableColumns"
      >
        <template #cell(debt_count)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_collect_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'true'})"
          >{{ data.item.debt_count || 0 }}</span>
        </template>

        <template #cell(total)="data">
          <span
            class="filterable"
            @click="openModalDebtDetail"
          >{{ formatNumber(data.item.total || 0) }}</span>
        </template>

        <template #cell(total_amount_for_team)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_collect_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'true'})"
          >{{ formatNumber(data.item.total_amount_for_team || 0) }}</span>
        </template>

        <template #cell(amountTeamLeader)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_collect_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'true'})"
          >{{ formatNumber(data.item.amountTeamLeader || 0) }}</span>
        </template>

        <template #cell(amountTeamMember)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'from_collect_at', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', hasCollectedFilter: 'true'})"
          >{{ formatNumber(data.item.amountTeamMember || 0) }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, BTable, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCardHeader,
    BCard,
    BTable,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    refs: {
      type: Array,
      default: () => [],
    },
    timeFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rangeDateFilter: '',
      tableColumns: [
        {
          label: 'TỔNG SỐ HĐ ĐÃ THU ĐƯỢC NỢ',
          key: 'debt_count',
        },
        {
          label: 'TỔNG SỐ TIỀN NỢ ĐÃ THU (VNĐ)',
          key: 'total',
        },
        {
          label: 'DOANH THU ĐỘI (VNĐ)',
          key: 'total_amount_for_team',
        },
      ],
    }
  },
  created() {
    const userData = getUserData()

    if (!userData.job_title_code || userData.job_title_code === 'TDPC') {
      this.tableColumns.push(
        {
          label: 'LƯƠNG ĐỘI TRƯỞNG',
          key: 'amountTeamLeader',
        },
        {
          label: 'LƯƠNG NHÂN VIÊN',
          key: 'amountTeamMember',
        },
      )
    }
  },
  methods: {
    openModalDebtDetail(data) {
      this.$emit('open-modal-debt-detail', data)
    },
    goContractList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    updateRangeFilter() {
      const q = this.$route.query
      const m = q.monthFilter || ''
      const y = q.yearFilter || ''
      if (!y) {
        this.rangeDateFilter = ' to '
      } else if (!m) {
        this.rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return this.rangeDateFilter
    },
  },
}
</script>
