import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import { avatarText } from '@core/utils/filter'

export default function useDebtCollectionList() {
  const isCollapsedCard = ref({
    pkh: false,
    dpc: false,
  })
  const isPopupDebtModal = ref(false)
  const debtCollectionModalMode = ref('create')
  const debtCollectionModalData = ref({})
  const debtCollections = ref([])
  const departments = ref([])
  const monthFilter = ref('')
  const yearFilter = ref('')
  const contractList = ref([])
  const departmentOptions = computed({
    get: () => {
      let list = departments.value.filter(d => d.code.toLowerCase().startsWith('d'))
      list = list.sort((a, b) => (a.code < b.code ? -1 : 1))
      return list
    },
    set: () => {},
  })
  const currentDebt = ref({})
  const searchQuery = ref('')
  const departmentFilter = ref('')

  // Table Handlers
  const tableColumns = [
    {
      label: 'STT',
      key: 'stt',
    },
    {
      label: 'NGÀY',
      key: 'date',
    },
    {
      label: 'ĐỘI',
      key: 'team',
    },
    {
      label: 'SỐ HĐ',
      key: 'so-hd',
    },
    {
      label: 'SỐ TIỀN DỰ KIẾN THU',
      key: 'so-tien-thu',
      thClass: 'border-right-gray',
    },
    {
      label: 'SỐ TIỀN ĐÃ THU',
      key: 'so-tien-thuc-thu',
    },
    {
      label: 'TỈ LỆ % ĐẠT ĐƯỢC',
      key: 'dat-duoc',
    },
    {
      label: '',
      key: 'actions',
    },
  ]
  const totalExpectedAmount = ref(0)
  const totalActualAmount = ref(0)
  const totalPercentAmount = ref(0)
  const perPage = ref(100)
  const currentPage = ref(1)
  const totalContracts = ref(0)
  const perPageOptions = [10, 25, 50, 100]
  const resultTitle = ref('')

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'primary'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const sortDebtCollections = () => {
    debtCollections.value = debtCollections.value.sort((a, b) => ((a.department_code || '').replace(/^\D+/g, '') > (b.department_code || '').replace(/^\D+/g, '') ? 1 : -1))
  }

  const getDateRange = () => {
    if (!yearFilter.value) {
      return {
        from: '',
        to: '',
      }
    }

    return {
      from: !monthFilter.value ? new Date(yearFilter.value, 0, 1)
        : new Date(yearFilter.value, parseFloat(monthFilter.value - 1), 1),
      to: !monthFilter.value ? new Date(yearFilter.value, 12, 0) : new Date(yearFilter.value, monthFilter.value, 0, 23, 59, 59),
    }
  }

  const fetchDebtCollections = filter => {
    const { from, to } = getDateRange()
    const queryString = http.buildQuery(filter || {
      page: currentPage.value - 1,
      perpage: perPage.value,
      q: searchQuery.value,
      department_id: departmentFilter.value,
      from: http.getISODate(from),
      to: http.getISODate(to),
    }) || ''
    return http.api(store.dispatch('debtCollection/fetchDebtCollections', queryString), res => {
      debtCollections.value = (res.data || []).map(item => ({
        ...item,
        // eslint-disable-next-line no-nested-ternary
        _rowVariant: (parseFloat(item.actual_amount || 0) < parseFloat(item.expected_amount || 0) ? (new Date(item.schedule_at).getTime() < new Date().getTime() ? 'danger' : 'warning') : ''),
      }))

      totalExpectedAmount.value = 0
      totalActualAmount.value = 0
      debtCollections.value.forEach(d => {
        totalExpectedAmount.value += parseFloat(d.expected_amount || 0)
        totalActualAmount.value += parseFloat(d.actual_amount || 0)
      })
      totalPercentAmount.value = `${Math.floor((100 * totalActualAmount.value) / totalExpectedAmount.value) || 0}%`

      sortDebtCollections()
    })
  }

  const fetchContractList = filter => {
    const queryString = http.buildQuery(filter || {
      page: currentPage.value - 1,
      perpage: 999,
      department_id: departmentFilter.value,
      m_status: 'contract_signed',
    }) || ''
    return http.api(store.dispatch('contract/fetchContracts', queryString), res => {
      contractList.value = res.data.filter(item => item.contract_code) || []
    })
  }

  const fetchDepartments = filter => {
    const queryString = http.buildQuery(filter || {
      page: 0,
      perpage: 999,
    }) || ''
    return http.api(store.dispatch('department/fetchDepartments', queryString), res => {
      departments.value = res.data || []
    })
  }

  const fetchDebtCollection = id => http.api(store.dispatch('debtCollection/fetchDebtCollection', { id }), res => {
    debtCollectionModalData.value = res.data
    isPopupDebtModal.value = true
  })

  const updateDebtCollection = data => http.api(store.dispatch('debtCollection/updateDebtCollection', data), res => {
    debtCollectionModalData.value = res.data
    Object.assign(debtCollections.value.find(u => u.id === res.data.id), res.data)
  })

  const updateDebtCollectionByAccountant = data => http.api(store.dispatch('debtCollection/updateDebtCollectionByAccountant', data), res => {
    debtCollectionModalData.value = res.data
    Object.assign(debtCollections.value.find(u => u.id === res.data.id), res.data)
  })

  const createDebtCollection = data => http.api(store.dispatch('debtCollection/createDebtCollection', data), res => {
    isPopupDebtModal.value = false
    debtCollections.value.unshift({
      ...res.data,
      department_id: res.data.department_id.toString(),
      // eslint-disable-next-line no-nested-ternary
      _rowVariant: (parseFloat(res.data.actual_amount || 0) < parseFloat(res.data.expected_amount || 0) ? (new Date(res.data.schedule_at).getTime() < new Date().getTime() ? 'danger' : 'warning') : ''),
    })

    sortDebtCollections()
  })

  const deleteDebtCollection = user => http.api(store.dispatch('debtCollection/deleteDebtCollection', user), () => {
    const idx = debtCollections.value.findIndex(u => u.id === user.id)
    debtCollections.value.splice(idx, 1)
  })

  watch([departmentFilter, monthFilter, yearFilter], () => {
    fetchDebtCollections()
  })

  return {
    totalExpectedAmount,
    totalActualAmount,
    totalPercentAmount,
    tableColumns,
    perPage,
    currentPage,
    totalContracts,
    perPageOptions,
    avatarText,
    resultTitle,
    fetchContractList,
    fetchDebtCollections,
    fetchDebtCollection,
    updateDebtCollection,
    updateDebtCollectionByAccountant,
    createDebtCollection,
    deleteDebtCollection,
    isCollapsedCard,
    debtCollections,
    searchQuery,
    departmentOptions,
    contractList,
    monthFilter,
    yearFilter,
    departmentFilter,
    fetchDepartments,
    departments,
    isPopupDebtModal,
    debtCollectionModalMode,
    debtCollectionModalData,
    currentDebt,
    resolveUserRoleVariant,
  }
}
