<template>
  <div>
    <b-card class="custom-border-top-1px">
      <b-card-header class="pl-0 pt-0">
        <b-badge
          variant="light-success"
          class="font-small-4 px-1 py-50"
        >
          BÁO CÁO HỒ SƠ
        </b-badge>
      </b-card-header>
      <b-table
        class="position-relative text-center table-col-max-width-100 table-col-min-width-100"
        responsive
        bordered
        small
        striped
        primary-key="id"
        show-empty
        empty-text="Không có kết quả nào"
        :items="tableData"
        :fields="tableColumns"
      >
        <template #cell(contract_count)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : ''})"
          >{{ formatNumber(data.item.contract_count || 0) }}</span>
        </template>

        <template #cell(company)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', categoryFilter: 'company'})"
          >{{ formatNumber(data.item.company || 0) }}</span>
        </template>

        <template #cell(personal)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', categoryFilter: 'personal'})"
          >{{ formatNumber(data.item.personal || 0) }}</span>
        </template>

        <template #cell(offer_to_sign)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'offer_to_sign'})"
          >{{ formatNumber(parseFloat(data.item.contract_signed || 0)
            + parseFloat(data.item.contract_open || 0)
            + parseFloat(data.item.liquidity_processing || 0)
            + parseFloat(data.item.liquidity_completed || 0),
          ) }}</span>
        </template>

        <template #cell(verify_deny)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'verify_deny'})"
          >{{ formatNumber(data.item.verify_deny || 0) }}</span>
        </template>

        <template #cell(verify_new)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'verify_new'})"
          >{{ formatNumber((data.item.verify_new || 0) + (data.item.verify_approving || 0)) }}</span>
        </template>

        <template #cell(total_offer_to_sign)="data">
          <span
            class="filterable"
            @click="goContractList({departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'offer_to_sign'}, false)"
          >{{ formatNumber(data.item.total_offer_to_sign || 0) }}</span>
        </template>

        <template #cell(contract_signed)="data">
          <span
            class="filterable"
            @click="goContractList({typeDateFilter: 'default', departmentFilter: data.item.department_id ? data.item.department_id.toString() : '', statusFilter: 'contract_signed'})"
          >{{ formatNumber(data.item.contract_signed || 0) }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, BTable, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardHeader,
    BCard,
    BTable,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    refs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rangeDateFilter: '',
      tableColumns: [
        {
          label: '',
          key: 'row_name',
          tdClass: 'font-weight-bolder',
        },
        {
          label: 'TỔNG HỒ SƠ',
          key: 'contract_count',
        },
        {
          label: 'HS CÔNG TY',
          key: 'company',
        },
        {
          label: 'HS CÁ NHÂN',
          key: 'personal',
        },
        {
          label: 'HS còn tồn',
          key: 'offer_to_sign',
        },
        {
          label: 'HS đã ký',
          key: 'contract_signed',
        },
        {
          label: 'HS TRẢ',
          key: 'verify_deny',
        },
        {
          label: 'HS ĐANG XM & CHỜ DUYỆT',
          key: 'verify_new',
        },
        {
          label: 'TỔNG HS ĐNK',
          key: 'total_offer_to_sign',
          tdClass: 'text-primary',
          stickyColumn: true,
        },
      ],
    }
  },
  methods: {
    goContractList(filter, withTimeFilter = true) {
      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            rangeDateFilter: withTimeFilter ? this.updateRangeFilter() : '',
            ...filter,
          }),
        },
      })
    },
    updateRangeFilter() {
      const q = this.$route.query
      const m = q.monthFilter || ''
      const y = q.yearFilter || ''
      if (!y) {
        this.rangeDateFilter = ' to '
      } else if (!m) {
        this.rangeDateFilter = `${y}-01-01 to ${y}-12-31`
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.rangeDateFilter = `${y}-${m}-01 to ${y}-${m}-${endDate}`
      }
      return this.rangeDateFilter
    },
  },
}
</script>
