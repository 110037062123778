<template>
  <div>
    <b-sidebar
      id="add-new-debt-collection-sidebar"
      :visible="isShow"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:isShow', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ modalMode === 'create' ? 'Tạo thu nợ' : 'Thông tin thu nợ' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div
              v-if="!modalData.contract_id"
            >
              <label
                class="field-required"
              >Mã HĐ <span class="text-danger">*</span></label>
              <v-select
                class="mb-1"
                label="contract_code"
                :value="modalData.contract_id"
                :options="contractOptions"
                :clearable="true"
                :reduce="val => '' + val.id"
                @input="val => modalData.contract_id = val.toString()"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  <strong class="text-primary font-small-3">{{ option.contract_code }}</strong>&nbsp;
                  <span class="text-muted font-small-2">{{ option.debtor.name }}</span>
                </template>
              </v-select>
            </div>
            <div
              v-else
              class="position-relative"
            >
              <div>
                <label
                  class="field-required"
                >Mã HĐ <span class="text-danger">*</span></label>
              </div>
              <p>
                <strong class="text-primary font-small-3">{{ (contractOptions.find(item => (item.id || '').toString() === (modalData.contract_id || '').toString()) || {}).contract_code }}</strong>&nbsp;
                <span class="text-muted font-small-2">{{ ((contractOptions.find(item => (item.id || '').toString() === (modalData.contract_id || '').toString()) || {}).debtor || {}).name }}</span>
              </p>
            </div>
            <form-required
              v-if="!autoSelectDepartmentId && !modalData.department_id"
              v-model="modalData.department_id"
              :select-options="departmentOptions"
              type="select"
              vid="department_id"
              label="Đội tiếp nhận"
              value-field="id"
              text-field="name"
              custom-v-error="Đội tiếp nhận là bắt buộc"
              rules="required"
              @update-value="val => modalData.department_id = val.toString()"
            />
            <div
              v-else
              class="position-relative"
            >
              <div>
                <label
                  class="field-required"
                >Đội tiếp nhận <span class="text-danger">*</span></label>
              </div>
              <p class="text-primary">
                {{
                  (departmentOptions.find(item => (item.id || '').toString() === (autoSelectDepartmentId || modalData.department_id || '').toString()) || {}).name
                }}
              </p>
            </div>

            <b-form-group
              label="Ngày thu nợ"
              label-for="ngay-thu-no"
              class="field-required"
            >
              <flat-pickr
                id="ngay-thu-no"
                v-model="modalData.schedule_at"
                class="form-control"
                :config="{ altFormat: 'd/m/Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Z', allowInput: false }"
              />
            </b-form-group>

            <b-form-group
              label="Số tiền thu"
              label-for="ngay-thu-no"
              class="field-required"
            >
              <cleave
                id="amount_to_buy_original"
                v-model="modalData.expected_amount"
                class="form-control text-primary"
                :raw="true"
                :options="formatOptions.number"
                placeholder="Số tiền thu"
              />
            </b-form-group>

            <form-required
              v-model="modalData.content"
              vid="content"
              label="Nội dung"
              @update-value="val => modalData.content = val"
            />

            <!-- Form Actions -->
            <div class="d-flex mt-2 justify-content-between flex-wrap">

              <div class="d-flex justify-content-end align-items-start flex-grow-1 ml-2">
                <b-button
                  v-if="modalMode === 'edit' && $ability().can('delete', 'DebtCollection')"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  class="mr-2"
                  variant="danger"
                  @click="$emit('delete')"
                >
                  Xóa
                </b-button>

                <b-button
                  v-if="showBtnInputDebtResult && modalMode === 'edit' && $ability().can('update', 'DebtCollectionActual')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  @click="$emit('input-debt-result')"
                >
                  Nhập kết quả
                </b-button>

                <b-button
                  v-if="modalMode === 'edit' && $ability().can('delete', 'DebtCollection')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  Lưu
                </b-button>

                <b-button
                  v-if="modalMode === 'create' && $ability().can('create', 'DebtCollection')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  Tạo
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Hủy
                </b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <modal-change-password
      id="modal-admin-change-password"
      ref="modal-admin-change-password"
      modal-mode="admin-change-password"
      :user="modalData"
    />
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BSidebar,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { alphaNum, email, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import FormRequired from '@core/components/form-required/FormRequired.vue'
import ModalChangePassword from '@/views/settings/users/list/ModalChangePassword.vue'
import flatPickr from 'vue-flatpickr-component'
import http from '@/global/http'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    FormRequired,
    ModalChangePassword,
    BFormGroup,
    Cleave,
    vSelect,

    // Form Validation
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShow',
    event: 'update:isShow',
  },
  props: {
    showBtnInputDebtResult: {
      type: Boolean,
      default: false,
    },
    modalMode: {
      type: String,
      default: null,
    },
    modalData: {
      type: Object,
      default: null,
    },
    autoSelectDepartmentId: {
      type: String,
      default: '',
    },
    isShow: {
      type: Boolean,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
    departmentOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      refreshUploadFile: 1,
      uploadDropzoneOptions: {
        uploadMultiple: false,
        maxFiles: 1,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
        },
      },
    }
  },
  watch: {
    modalData() {
      this.refreshUploadFile += 1
    },
    // departmentOptions(n) {
    //   this.modalData.department_id = n.find(item => (item.id || '').toString() === (this.autoSelectDepartmentId || this.modalData.department_id || '').toString()).id.toString()
    // },
    autoSelectDepartmentId: {
      immediate: true,
      handler(n) {
        this.blankModalData.department_id = n.toString()
        this.modalData.department_id = n.toString()
      },
    },
  },
  methods: {
    openModalChangePassword() {
      this.$refs['modal-admin-change-password'].$refs['btn-open-modal-change-password'].click()
    },
    handleUploaded(files = []) {
      const avatar = files[0].thumbnail || ''
      Object.assign(this.modalData, { avatar })
    },
  },
  setup(props, { emit }) {
    const blankModalData = {
      contract_id: null,
      department_id: props.autoSelectDepartmentId.toString() || null,
      schedule_at: '',
      expected_amount: null,
      content: '',
    }

    const onSubmit = () => emit('submit', props.modalMode, {
      ...props.modalData,
      join_at: http.getISODate(props.modalData.join_at),
    })

    const debtCollectionData = ref({
      ...blankModalData.value,
    })

    const resetUserData = () => {
      debtCollectionData.value = JSON.parse(JSON.stringify(blankModalData))
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetUserData)

    return {
      debtCollectionData,
      onSubmit,
      refFormObserver,
      blankModalData,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-debt-collection-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
